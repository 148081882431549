import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { getAllCategories } from "../../fetch/products/apies";
import { useGetProducts } from "../../hooks/getProducts";
import { useTranslation } from "react-i18next";
import Clients from "../../Components/Clients";
import { Products } from "../../Components/Products";

const Home = () => {
  const { t, i18n } = useTranslation();
  const { loading, error, products } = useGetProducts();

  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res?.data?.data?.items);
    });
  }, []);

  const tabCategory = (id) => {
    setActiveTab(id);
  };

  return (
    <Fragment>
      <Navbar />
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">{t("intro.header")}</h1>
              <h2 data-aos="fade-up" data-aos-delay={400}>
                {t("intro.desc")}
              </h2>
              <div data-aos="fade-up" data-aos-delay={600}>
                <div className="text-center text-lg-start">
                  <a
                    href="#services"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span> {t("intro.get_started")}</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay={200}
            >
              <img
                src="assets/img/hero-img.png"
                className="img-fluid"
                alt="stack-image"
              />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        {/* ======= Services Section ======= */}
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("Stacks")}</h2>
              {/* <p>Veritatis et dolores facere numquam et praesentium</p> */}
            </header>
            <div className="row gy-4 stack_box justify-content-center">
              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="service-box purple">
                  <i className="icon">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/5968/5968282.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>Java</h3>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="service-box purple">
                  <i className="icon">
                    <img
                      src="https://img.icons8.com/color/512/spring-logo.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>Spring</h3>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="service-box red">
                  <i className="icon">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMoAAAD6CAMAAADXwSg3AAAAvVBMVEX///9HxfsAVp4Atfg2wvvV7/4AUJuBm8JKxvsAsPgzwfsAVJ3w+v+45v0Au/8ARpdhzPsAQ5Zfy/vA6P1uz/z0+/+C1fyx4/0AUpcARH21x90ATpAASJgAR4MATJrq+P+R2fsAOWna5O8AS4oAPnLi6vKE1vyswNkSlNS6y9+Z2/zE0+QMhbun3/wANWHN2+kCHELa8v4Ai88DSI0DP34PjcgDOHECMGQCKlgCGj8ALlYAKEoIf6+y1OkANoR05V+0AAAEoklEQVR4nO3ajVYSURQF4HFAC7AhtFREM5JMDckyMs16/8fqDoL8OMD92eM596xzXoD1ufdmRpdJQncnrzdgV31HCElOcJCN6p5K2EnktEsl/CRydiJHIqddKlHJvETOe5cYyTYOQp2JSvhJxOxEjmRbjgQHoW6XHImYdsmR6OJVUt7J2YkciS5eJeWd7oSf5L0cCQ6i7VKJWIkunp9ETrtUwk8iaPFiJHLapRJ+Ejk7kSOR0y6V8JPI2Ym+1atEJetPF89PIqddKuEn0cXzk8hpl0r4SQQtXoxETrtUwk8iZyf6jFeJStafoMWLkchpl0r4SaA7eStGou1SSXkSOTuRI5HTLpVATnciXCKnXSrhJ5GzEzkSOe2SI5HTLpWoRKpkR4xETiYqUUl5EjmLlyNBtmvH5hNPNpFXksQqk816FXf1/ankxdu1Wcd94kb9DaWkivtEoZIXX3xp7SJYPO4TidsVg8SuXTEsnlSSXOLCpl28uT3UD4l2J6M7w1gYSEAW4p1MDtAxJhKAhXzxMAsjSaCFlSTIwmYnwRZ2Em8Ls3YFWFhKvCwsnvEQC+1vv0gLY4mjhelOPCxsd+JsYd0uJwvDJ6OnxS6TXbp2WVvsMtmtVeqwP7L5SCwslpnUKhWYxU+y1uIgQVl8JWss1u2qwCz+kpUWRwnCEiJZYXGWhFvCJEstTjvBWEIlSywemYRawiWFFk9JiAUhKbB4S/wtGMkzS4DE14KSLFiCJH4WnGTOEijxsSAlM5ZgibsFK3myACSuFrRkbIFI3Cwz/7eCtHg948MsZUiS5KyOycTFUo4kSS5hEltLWRKrs5TYWeKQ2Fhikay3xCNZZ4lJstoSl2SVJTbJckt8kmWWGCXFljglRZZYJc8t8UoWLTFL5i1xS2YtsUumlvglE4sEyaNFhiS3kEq+4yTGAv8rkdOd4SzZjy+kFJwlO99qElv2MBYjSRvUFkguucRci9jyMdwylhjLYeSWJ0maNuO2zEjSxhGxJWgvsxJjiTiXeUnMe8nOb9JUhMVIGouUODtmJAeLkjgt2fDmoIBiLBeRWbLhz04hJW204rLkkk6hJN9+TBYjOV0SSmQdG0mWhpI/94ktl7YWIzleSaHPxdKSDX8dn67o1+P2v0ZgySVrQonEYiTtnLIylCg6lkvao349e2lZPObbz4a37bZFv0bX5NyxrH/bG4diQSHfy9VySy4ZUToW/WJtmUisKWYvPC1Z/3e359AvvpZHSS8PxYFCvv0CSy7pTvtlKzHvydwsRjLouvcrzZ+VvCwTSc85lJTZXrL+9WAw7ZebJE0/fGNjMZL7J0rH0ZHmv78QW/ZrC5Jur33qGsj4WjwsWf/uPqf0fB35NTl0bCS57x4HOPKj3ovJJXu4+zNoBzrMNagtV7WHa4CDheXvP4hjdNR7OWzavQPbHPV3MtBC3rHDForCwQLM5ROxBdixJrXlSFAukjoG3P4WuUVQLsDtHwmykOcC7Bj9XlCUtNEgtwBz+UxskbQXpIU6F2DHGmphaRG1fUEW8lyAHUvJLSgKB4ug7zHdfqGFPBdR299C3Qfq7+SLV8BL/gOmsOtv40/WkgAAAABJRU5ErkJggg=="
                      alt="stack-image"
                      style={{ height: "130px" }}
                    />
                  </i>
                  <h3>Flutter</h3>
                </div>
              </div>

              {/* <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={700}
              >
                <div className="service-box pink">
                  <i className="icon">
                    <img
                      src="https://cdn.worldvectorlogo.com/logos/strapi-2.svg"
                      alt="stack-image"
                    />
                  </i>
                  <h3>Strapi</h3>
                </div>
              </div> */}

              {/* <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="service-box green">
                  <i className="icon">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/5968/5968292.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>JavaScript</h3>
                </div>
              </div> */}

              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="service-box orange">
                  <i className="icon">
                    <img
                      src="https://cdn.iconscout.com/icon/free/png-256/node-js-1174925.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>Node js</h3>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="service-box blue">
                  <i className="icon">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>React</h3>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={700}
              >
                <div className="service-box pink">
                  <i className="icon">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/7/74/Kotlin_Icon.png"
                      alt="stack-image"
                    />
                  </i>
                  <h3>Kotlin</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Section */}

        {/* ======= Portfolio Section ======= */}
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("Portfolio")}</h2>
              <p>{t("portfolio_desc")}</p>
            </header>
            {/* <div className="row" data-aos="fade-up" data-aos-delay={100}>
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li
                    className={`${activeTab === 0 ? "filter-active" : ""}`}
                    onClick={() => setActiveTab(0)}
                  >
                    {t("All")}
                  </li>
                  {categories?.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => tabCategory(item?.id)}
                      className={activeTab === item.id ? "filter-active" : ""}
                    >
                      {localStorage.getItem("lng") === "en"
                        ? item.nameEn
                        : localStorage.getItem("lng") === "uz"
                        ? item.nameUz
                        : item.nameRu}
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
            <div
              className="row gy-4 portfolio-container"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              {error && (
                <p
                  className="text-center text-danger"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  {error}
                </p>
              )}

              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : products?.data?.items?.length ? (
                activeTab !== 0 ? (
                  products?.data?.items
                    .filter((item) => item.categoryId === activeTab)
                    ?.map((item, index) => (
                      <div
                        className="col-lg-4 col-md-6 portfolio-item filter-app"
                        key={index}
                        style={{
                          width: "400px",
                          height: "250px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className="portfolio-wrap"
                          style={{ height: "100%" }}
                        >
                          <div>
                            <img
                              src={`${process.env.REACT_APP_API}/file/${item?.photos[0]}`}
                              className="img-fluid w-100"
                              alt={item.nameEn}
                              style={{
                                position: "absolute",
                                height: "100%",
                              }}
                            />
                          </div>
                          <div className="portfolio-info">
                            <h4>
                              {localStorage.getItem("lng") === "en"
                                ? item.nameEn
                                : localStorage.getItem("lng") === "uz"
                                ? item.nameUz
                                : item.nameRu}
                            </h4>
                            {/* <p>
                              {localStorage.getItem("lng") === "en"
                                ? item.nameEn
                                : localStorage.getItem("lng") === "uz"
                                ? item.nameUz
                                : item.nameRu}
                            </p> */}
                            <div className="portfolio-links">
                              <a href={`/project/${item?.id}`}>
                                <i className="bi bi-play-fill"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  products?.data?.items?.map((item, index) => (
                    <div
                      className="col-lg-4 col-md-6 portfolio-item filter-app"
                      key={index}
                      style={{
                        width: "400px",
                        height: "250px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="portfolio-wrap"
                        style={{ height: "100%" }}
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_API}/file/${item?.photos[0]}`}
                            className="img-fluid w-100"
                            alt={item.nameEn}
                            style={{
                              position: "absolute",
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="portfolio-info">
                          <h4>
                            {localStorage.getItem("lng") === "en"
                              ? item.nameEn
                              : localStorage.getItem("lng") === "uz"
                              ? item.nameUz
                              : item.nameRu}
                          </h4>
                          {/* <p>
                            {localStorage.getItem("lng") === "en"
                              ? item.nameEn
                              : localStorage.getItem("lng") === "uz"
                              ? item.nameUz
                              : item.nameRu}
                          </p> */}
                          <div className="portfolio-links">
                            <Link to={`/project/${item.id}`}>
                              <i className="bi bi-play-fill"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : error ? (
                ""
              ) : (
                <h6 className="text-center">Project not found</h6>
              )}
            </div>
          </div>
        </section>
        {/* End Portfolio Section */}

        {/* ======= Team Section ======= */}
        <section id="team" className="team">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("Team")}</h2>
              <p>{t("Team_desc")}</p>
            </header>
            <div className="row gy-4">
              {/* Mirzohid */}
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/detirx02f/image/upload/v1684843930/Mirzohid_Karimov_2_rf8b7u.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Mirzohid Karimov</h4>
                    <span>Director of company</span>
                    {/* <div className="social">
                      <a target="_blank" href="https://t.me/Samandar_78">
                        <i className="bi bi-telegram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/samandar.abduhamitov.3/"
                      >
                        <i className="bi bi-facebook" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/samandar.7978/"
                      >
                        <i className="bi bi-instagram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/samandar-abduhamitov-bb631620a/"
                      >
                        <i className="bi bi-linkedin" />
                      </a>
                    </div> */}
                    {/* <p>
                      Rerum voluptate non adipisci animi distinctio et deserunt
                      amet voluptas. Quia aut aliquid doloremque ut possimus
                      ipsum officia.
                    </p> */}
                  </div>
                </div>
              </div>

              {/* Tohir */}
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1673005552/geo2_iut_global/1213213213131_vw2p9p.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Kodirov Tokhirjon</h4>
                    <span>Fullstack developer</span>
                    <div className="social">
                      <a href="https://t.me/fcb2000" target={"_blank"}>
                        <i className="bi bi-telegram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Abduqayum */}
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1670759292/geo2_iut_global/photo_2022-06-17_16-37-13_actszj.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Abduqayum Fayzullayev</h4>
                    <span>Fullstack developer</span>
                    <div className="social">
                      <a target={"_blank"} href="https://t.me/mr_Abduqayum">
                        <i className="bi bi-telegram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/abduqayum.fayzulloev.5"
                      >
                        <i className="bi bi-facebook" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/abduqayum_fayzullaev/"
                      >
                        <i className="bi bi-instagram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/abduqayum-fayzullayev-025b64225/"
                      >
                        <i className="bi bi-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dostonbek */}
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1673003722/geo2_iut_global/asdsadaads_z7wnov.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Gayipov Dostonbek</h4>
                    <span>Fullstack developer</span>
                    <div className="social">
                      <a target={"_blank"} href="https://t.me/Gayipov1999">
                        <i className="bi bi-telegram" />
                      </a>
                      <a
                        target={"_blank"}
                        href="https://www.facebook.com/dostonbek.goyipov.9"
                      >
                        <i className="bi bi-facebook" />
                      </a>
                      <a
                        target={"_blank"}
                        href="https://www.instagram.com/dostonbek_gayipov/"
                      >
                        <i className="bi bi-instagram" />
                      </a>
                      <a
                        target={"_blank"}
                        href="https://www.linkedin.com/in/dostonbek-g-oyipov-a9b01023a/"
                      >
                        <i className="bi bi-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Jonibek */}
              {/* <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1670762650/geo2_iut_global/Jonibek_qfmg0y.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Ashirov Jonibek</h4>
                    <span>Fullstack developer</span>
                    <div className="social">
                      <a target={"_blank"} href="https://t.me/javist_uz">
                        <i className="bi bi-telegram" />
                      </a>

                      <a
                        target={"_blank"}
                        href="https://instagram.com/ashirov_jonibek"
                      >
                        <i className="bi bi-instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* Yunus */}
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1673003010/geo2_iut_global/Untitled-1_copy_tkvyim.jpg"
                      className="img-fluid"
                      alt="stack-image"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Boliyev Yunus</h4>
                    <span>Fullstack developer</span>
                    <div className="social">
                      <a href="https://t.me/yunus_3101" target={"_blank"}>
                        <i className="bi bi-telegram" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/yunus-boliyev-735b1622a/"
                        target={"_blank"}
                      >
                        <i className="bi bi-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Team Section */}

        {/* ======= Products Section ======= */}
        <section id="products" className="contact">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("products")}</h2>
              <p>{t("ourProducts")}</p>
            </header>
            <Products />
          </div>
        </section>
        {/* ======= End Products Section ======= */}

        {/* ======= Clients Section ======= */}
        <section id="clients" className="contact">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("clients")}</h2>
              <p>{t("ourClients")}</p>
            </header>
            <div className="row">
              <div className="col"><Clients /></div>
            </div>
          </div>
        </section>
        {/* ======= End Clients Section ======= */}

        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{t("Contact")}</h2>
              <p>{t("Contact_us")}</p>
            </header>
            <div className="row gy-4">
              <div className="col-md-6 col-lg-3">
                <div className="info-box text-center">
                  <i className="bi bi-geo-alt" />
                  <h3>{t("Address")}</h3>
                  <p>
                    Ziyolilar street 9,
                    <br />
                    M.Ulugbek district, Tashkent city
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="info-box text-center">
                  <i className="bi bi-telephone" />
                  <h3>{t("Call Us")}</h3>
                  <p>
                    <a style={{ color: "#444444" }} href="tel:+998909647853">
                      +998 90 964 78 53
                    </a>
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="info-box text-center">
                  <i className="bi bi-envelope" />
                  <h3>{t("Email Us")}</h3>
                  <p>
                    <a
                      style={{ color: "#444444" }}
                      href="mailto:z.davlatov@inha.uz"
                    >
                      z.davlatov@inha.uz
                    </a>
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="info-box text-center">
                  <i className="bi bi-clock" />
                  <h3>{t("Open Hours")}</h3>
                  <p>
                    {t("Monday")} - {t("Friday")}
                    <br />
                    9:00AM - 06:00PM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </main>
    </Fragment>
  );
};

export default Home;
