import React, { useEffect, useState } from "react";
import { getAllProducts } from "../../fetch/products/apies";
import "./style.css";

export const Products = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then((res) => {
      console.log(res);
      setProducts(res?.data?.data);
    });
  }, []);

  console.log("products", products);

  return (
    <>
      <div className="card-5 products">
        <div className="row">
          {products.map((item) => (
            <div key={item.name} className="col">
              <img width={"100%"} src={item.photo} alt={item.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="normall products">
        <div className="row ">
          {products.map((item) => (
            <div key={item.name} className="col-sm-6 col-md-4 col-lg-2">
              <img width={"100%"} src={item.photo} alt={item.name} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
