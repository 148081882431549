import { useEffect, useState } from "react";
import { getProducts } from "../fetch/products/apies";

export const useGetProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchProduct = () => {
    setError("");
    setLoading(true);
    getProducts()
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("Error: ", err);
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return { products, error, loading };
};
