import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a
                href="index.html"
                className="logo d-flex align-items-center justify-content-center justify-content-lg-start"
              >
                {/* <img src="assets/img/logo.png" alt="" /> */}
                <span>Geo2 IUT Global</span>
              </a>
              <p>
                {t("intro.header")}, {t("intro.desc")}
              </p>

              <div className="row d-flex align-items-center">
                <div className="col-4 col-md-3">
                  <img width={'100%'} src="assets/img/Emblem-IUT.jpg" alt="logo" />
                </div>
                <div className="col-4 col-md-3">
                  <img width={'100%'} src="assets/img/logo12.PNG" alt="logo" />
                </div>
                <div className="col-4 col-md-3">
                  <img width={'100%'} src="assets/img/geoTwoLogo.png" alt="logo" />
                </div>
              </div>
              {/* <div className="social-links mt-3">
                <a href="#" className="twitter">
                  <i className="bi bi-twitter" />
                </a>
                <a href="#" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="#" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="#" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div> */}
            </div>
            {/* <div className="col-lg-2 col-6 footer-links">
              <h4>{t("USEFUL LINKS")}</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#hero">{t("navbar.Home")}</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#services">{t("navbar.Services")}</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#portfolio">{t("navbar.Portfolio")}</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#team">{t("navbar.Team")}</a>
                </li>
              </ul>
            </div> */}
            {/* <div className="col-lg-2 col-6 footer-links">
              <h4>{t("OUR SERVICES")}</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div> */}
            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>{t("Contact_us")}</h4>
              <p>
                Ziyolilar street 9, <br />
                M.Ulugbek district,
                <br />
                Tashkent city <br />
                <br />
                <strong>{t("Phone")}:</strong> <a style={{color: "#444444"}} href="tel:+998 90 964 78 53">+998 90 964 78 53</a>
                <br />
                <strong>{t("Email")}:</strong> <a style={{color: "#444444"}} href="mailto:z.davlatov@inha.uz">z.davlatov@inha.uz</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>Geo2 IUT Global</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
