import { Fragment } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import AOS from "aos";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import AppPath from "./AppPath/AppPath";

function App() {
  AOS.init({
    // initialise with other settings
    duration: 1000,
  });
  return (
    <Router>
      <AppPath />
      <Footer />
    </Router>
  );
}

export default App;
