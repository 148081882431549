import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import { getAllCategories, getAllClients } from "../../fetch/products/apies";

const clientsData = [
  {
    name: "INHA",
    photo: "assets/img/Emblem-IUT.jpg",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/1.jpg",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/2.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/3.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/4.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/5.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/6.png",
  },
  {
    name: "INHA",
    photo: "assets/img/geoTwoLogo.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/7.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/8.png",
  },
  {
    name: "INHA",
    photo: "assets/img/clients/9.png",
  },
];

export default function Clients() {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getAllClients().then((res) => {
      setClients(res?.data?.data);
    });
  }, []);

  console.log(clients);
  return (
    <>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        spaceBetween={10}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {clientsData.map((item, inx) => (
          <SwiperSlide className="h-100" key={inx}>
            <div className="client-img">
              <img width={"100%"} src={item.photo} alt={item.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
