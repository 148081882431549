import { Fragment, useEffect, useState } from "react";
import "../../i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getProjectById } from "../../fetch/products/apies";

const Details = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [project, setProject] = useState();
  const [appUrls, setAppUrls] = useState();
  const [technology, setTechnology] = useState();

  const language = localStorage.getItem("lng");

  const path = window.location.href.split("/");
  const project_id = path[path.length - 1];

  useEffect(() => {
    getProjectById(project_id)
      .then((result) => {
        setProject(result.data.data);
        setAppUrls(Object.entries(JSON.parse(result?.data?.data?.links)));
        setTechnology(
          Object.entries(JSON.parse(result?.data?.data?.technology))
        );
        console.log("technology: ", technology);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(project);
  // console.log(JSON.parse(project?.links));

  const formatDate = (date) => {
    const spl = date?.split("-");

    return `${spl?.[2]?.split("T")?.[0]}-${spl?.[1]}-${spl?.[0]}`;
  };

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (lng) => {
    handleChangeLng(lng);
  };

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a href="/" className="logo d-flex align-items-center navbar-brand">
            {/* <img
              src="https://res.cloudinary.com/apple-angren/image/upload/v1672382727/my-icons/logo_cldo5b.png"
              alt=""
            /> */}
            <span>Geo2 IUT Global</span>
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="dropdown">
                <a href="#" className="trigger-drop getstarted scrollto">
                  {t("navbar.Language")}
                </a>
                <ul className="drop">
                  <li
                    onClick={() => handleChange("ru")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Ru
                    <img
                      style={{ width: "30px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1672383305/my-icons/527-5275877_-png_fyn2m5.png"
                      alt=""
                    />
                  </li>
                  <li
                    onClick={() => handleChange("en")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    En
                    <img
                      style={{ width: "30px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1672299756/my-icons/english-language-flag-round-11562903960vbmoq86rb5_1_or78no.png"
                      alt=""
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <section className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <span
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                >
                  {t("navbar.Home")}
                </span>
              </li>
              <li>{t("Portfolio Details")}</li>
            </ol>
            <h2>
              {language === "en"
                ? project?.nameEn
                : language === "uz"
                ? project?.nameUz
                : project?.nameRu}
            </h2>
          </div>
        </section>
        {/* End Breadcrumbs */}
        {/* ======= Portfolio Details Section ======= */}
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-8">
                {/* <div className="portfolio-details-slider swiper">
                <div className="swiper-wrapper align-items-center">
                  <div className="swiper-slide">
                    <img
                      src="https://picsum.photos/seed/picsum/200/100"
                      alt=""
                    />
                  </div>
                  <div className="swiper-slide">
                    <img
                      src="https://picsum.photos/seed/picsum/200/100"
                      alt=""
                    />
                  </div>
                  <div className="swiper-slide">
                    <img
                      src="https://picsum.photos/seed/picsum/200/100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="swiper-pagination" />
              </div> */}
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide mb-lg-0 mb-md-4 mb-sm-0"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <div className="carouseWrapperImg">
                      {project?.photos?.map((item, index) => (
                        <button
                          key={index}
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={index}
                          className={`${index === 0 ? "active" : ""}`}
                          aria-current="true"
                          aria-label="Slide 1"
                        >
                          <img
                            src={`${process.env.REACT_APP_API}/file/${item}`}
                            alt={item}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="carousel-inner">
                    {project?.photos?.map((item, index) => (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <div className="carouseImgBox">
                          <img
                            src={`${process.env.REACT_APP_API}/file/${item}`}
                            className="d-block"
                            alt={item}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-info">
                  <h3>{t("Project information")}</h3>
                  <ul>
                    <li>
                      <strong>{t("Category")}</strong>:{" "}
                      {language === "en"
                        ? project?.category?.nameEn
                        : language === "uz"
                        ? project?.category?.nameUz
                        : project?.category?.nameRu}
                    </li>
                    <li>
                      <strong>{t("Name")}</strong>:{" "}
                      {language === "en"
                        ? project?.nameEn
                        : language === "uz"
                        ? project?.nameUz
                        : project?.nameRu}
                    </li>
                    <li>
                      <strong>{t("Project date")}</strong>:{" "}
                      {formatDate(project?.projectDate)}
                    </li>

                    <li>
                      <strong>{t("Project URL")}</strong>:{" "}
                      <a href={project?.projectUrl} target="_blank">
                        {project?.projectUrl}
                      </a>
                    </li>

                    <li style={{ display: "flex", flexWrap: "wrap" }}>
                      <strong>{t("Technology")}:</strong>{" "}
                      {technology?.map((item, index) => (
                        // <img
                        //   key={index}
                        //   src={item?.[1]?.[`technology_image_url-${index}`]}
                        //   alt={item?.[1]?.[`technology_name-${index}`]}
                        //   style={{ width: "30px" }}
                        // />
                        <span
                          className="hashtagTechno"
                          key={index}
                          style={{
                            marginLeft: `${index === 0 ? "10px" : "0"}`,
                            marginBottom: "5px",
                          }}
                        >
                          {item?.[1]?.[`technology_name-${index}`]}
                        </span>
                      ))}
                    </li>
                    <li>
                      {appUrls?.map((item, index) => (
                        <a
                          href={item?.[1]?.[`link_url-${index}`]}
                          target="_blank"
                          key={index}
                        >
                          <img
                            src={item?.[1]?.[`link_image-${index}`]}
                            alt={item?.[1]?.[`link_image-${index}`]}
                            style={{ width: "130px" }}
                          />
                        </a>
                      ))}
                    </li>
                  </ul>
                </div>
                <div className="portfolio-description">
                  <h2>
                    {language === "en"
                      ? project?.shortDescriptionEn
                      : language === "uz"
                      ? project?.shortDescriptionUz
                      : project?.shortDescriptionRu}
                  </h2>
                  <p>
                    {language === "en"
                      ? project?.descriptionEn
                      : language === "uz"
                      ? project?.descriptionUz
                      : project?.descriptionRu}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Details Section */}
      </main>
    </Fragment>
  );
};

export default Details;
