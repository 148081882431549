import axios from "axios";
import { RequestType } from "../../constants/request-type";

const SECOND = 1000;
// const baseAPI = process.env.REACT_APP_PATH_API;

const axiosRequestConfig = (type) => ({
  baseURL: process.env.REACT_APP_API,
  timeout: 3 * SECOND,
  headers: {
    ...(type === RequestType.private && {
      Authorization: "Bearer " + localStorage.getItem("token"),
    }),
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

const SetupAxiosInstance = (type) => {
  const instance = axios.create(axiosRequestConfig(type));

  const onFulfilledReq = (config) => {
    return config;
  };

  const onRejectedReq = (error) => {
    return Promise.reject(error);
  };

  const onFulfilledRes = (response) => {
    // return {
    //   status: response.status,
    //   statusText: response.statusText,
    //   data: response.data,
    //   headers: response.headers,
    // };
    return response;
  };

  const onRejectedRes = (error) => {
    return Promise.reject(error);
  };

  instance.interceptors.request.use(onFulfilledReq, onRejectedReq);
  instance.interceptors.response.use(onFulfilledRes, onRejectedRes);

  return instance;
};

export default SetupAxiosInstance;
