import "../../i18next";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (lng) => {
    console.log(lng);
    handleChangeLng(lng);
  };

  return (
    <Fragment>
      <nav className="navbar  navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a href="/" className="logo d-flex align-items-center navbar-brand">
            <img width={'130px'} src="assets/img/logo12.PNG" alt="logo" />
            {/* <span>Geo2 IUT Global</span> */}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link scrollto" href="#hero">
                  {t("navbar.Home")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scrollto" href="#services">
                  {t("navbar.Services")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scrollto" href="#portfolio">
                  {t("navbar.Portfolio")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scrollto" href="#team">
                  {t("navbar.Team")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scrollto" href="#products">
                  {t("products")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scrollto" href="#clients">
                  {t("clients")}
                </a>
              </li>
              <li className="dropdown">
                <a href="#" className="trigger-drop">
                  {t("navbar.Language")}
                </a>
                <ul className="drop">
                  <li
                    onClick={() => handleChange("ru")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Ru
                    <img
                      style={{ width: "30px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1672383305/my-icons/527-5275877_-png_fyn2m5.png"
                      alt=""
                    />
                  </li>
                  <li
                    onClick={() => handleChange("en")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    En
                    <img
                      style={{ width: "30px" }}
                      src="https://res.cloudinary.com/apple-angren/image/upload/v1672299756/my-icons/english-language-flag-round-11562903960vbmoq86rb5_1_or78no.png"
                      alt=""
                    />
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="getstarted scrollto" href="#contact">
                  {t("navbar.Contact")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <header id="header" className="header sticky-top bg-white">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt="" />
          <span>IT-solutions</span>
        </a>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link scrollto" href="/">
                {t("navbar.Home")}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#services">
                {t("navbar.Services")}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#portfolio">
                {t("navbar.Portfolio")}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#team">
                {t("navbar.Team")}
              </a>
            </li>
            <li className="dropdown">
              <a href="#" className="trigger-drop">
                {t("navbar.Language")}
              </a>
              <ul className="drop">
                <li onClick={() => handleChange("ru")}>Ru</li>
                <li onClick={() => handleChange("en")}>En</li>
              </ul>
            </li>

            <li>
              <a className="getstarted scrollto" href="#contact">
                {t("navbar.Contact")}
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" />
        </nav>
      </div>
    </header> */}
    </Fragment>
  );
};

export default Navbar;
