import SetupAxiosInstance from "../../configs/axios/axios_config";
import { RequestType } from "../../constants/request-type";

const publicApi = SetupAxiosInstance(RequestType.public);

const getProducts = () => {
  return publicApi.get("/project?expand=category");
};

const getProjectById = (id) => {
  return publicApi.get(`/project/${id}?expand=category`);
};

const getAllCategories = () => {
  return publicApi.get("/category");
};

const getAllClients = () => {
  return publicApi.get("/client/get-all");
};

const getAllProducts = () => {
  return publicApi.get("/product/get-all");
};


const postProduct = (data) => {
  return publicApi({
    method: "post",
    url: "/products",
    data: JSON.stringify(data),
  });
};

export { getProducts, postProduct, getAllCategories, getProjectById, getAllClients,  getAllProducts };
